<template>
  <div>
    <headers />
    <div class="head">
      <img src="@/assets/images/toolDetailsBj.png" />
      <div class="title">{{ details.name }}</div>
    </div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>资料获取</el-breadcrumb-item>
        <el-breadcrumb-item to="/information/tool">软件及工具</el-breadcrumb-item>
        <el-breadcrumb-item>{{ details.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <div class="menu">
        <div
          class="menuItem"
          @click="changAct(0)"
          :style="act == 0 ? 'color:#00D8C3' : ''"
        >
          软件概述
        </div>
        <div
          class="menuItem"
          @click="changAct(1)"
          :style="act == 1 ? 'color:#00D8C3' : ''"
        >
          查看下载
        </div>
      </div>
      <div class="contentItem">
        <div id="section0">
          <div class="headTab">软件概述</div>
          <div class="text">
            {{ details.softwareDesc }}
          </div>
        </div>
        <div id="section1">
          <div class="headTab mt-60">查看下载</div>
          <!-- <el-button class="preview" @click="downZip">下载</el-button> -->
          <div class="downText" @click="downZip">
            <img src="@/assets/images/downIcon.png" />
            <span class="downName textOverflowOne">{{details.urlName}}</span>
            <span class="downSize">（{{details.urlSize}} MB）</span>
          </div>
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>

<script>
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";
import search from "@/components/search.vue";

import { softwareView } from "@/api/lx";

export default {
  name: "Procucts",
  components: {
    headers,
    footers,
    search,
  },
  data() {
    return {
      act: 0,
      id: "",
      details: {},
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getSoftwareView();
  },
  mounted() {},
  methods: {
    getSoftwareView() {
      softwareView(this.id).then((res) => {
        this.details = res.data;
      });
    },
    downZip() {
      const url = this.details.downloadUrl;
      const link = document.createElement("a");
      link.href = url;
      link.download = "file.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    changAct(val) {
      this.act = val;
      const section = document.getElementById("section" + val);
      if (section) {
        const targetTop = section.offsetTop - 75;
        console.log(section);
        window.scrollTo({
          top: targetTop,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.head {
  width: 100%;
  height: 300px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 42px;
    color: #ffffff;
    position: relative;
    z-index: 100;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.content {
  width: 1400px;
  margin: 0 auto;
  min-height: 500px;
  display: flex;
  padding: 40px 80px;
  box-sizing: border-box;
  background-color: #fff;
  margin-top: 30px;
  margin-bottom: 55px;
  display: flex;
  cursor: pointer;
  .menu {
    width: 95px;
  }
  .menuItem {
    font-weight: 400;
    font-size: 22px;
    color: #00161a;
    margin-bottom: 25px;
  }
  .contentItem {
    width: 1193px;
    border-left: 1px solid #e6edf0;
    margin-left: 40px;
    padding-left: 40px;
    box-sizing: border-box;
    .headTab {
      width: 100%;
      height: 66px;
      box-sizing: border-box;
      background: #e6fbf9;
      padding-left: 30px;
      line-height: 66px;
      font-weight: 600;
      font-size: 22px;
      color: #00d8c3;
    }
    .text {
      width: 100%;
      word-break: break-all;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #525f66;
      line-height: 28px;
      text-align: left;
      font-style: normal;
      margin-top: 30px;
    }
    .preview {
      width: 127px;
      height: 47px;
      background: #00d8c3;
      font-weight: 600;
      font-size: 18px;
      margin-top: 30px;
      color: #ffffff;
      &.is-active,
      &:active {
        border: 1px solid #00d8c3; /* 更改为你想要的颜色 */
        outline: none; /* 移除默认的轮廓线 */
      }
    }
  }
}
.downText {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
  img {
    width: 16px;
    height: 16px;
    display: block;
    margin-right: 8px;
  }
  .downName {
    max-width: 298px; 
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #00d8c3;
    margin-top: 2px;
  }
  .downSize {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #00d8c3;
    margin-left: 4px;
  }
}
</style>